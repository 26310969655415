.autocomplete {
  top: 90%;
  left: 0;
  right: 0;
  border: 1px solid #000000;
  background-color: white;
}

.items-container {
  min-height: 100px;
  max-height: 250px;
  overflow-y: auto;
  margin: 5px;
}

.remove-shop {
  color: white;
  background-color: orangered;
  padding-top: 1px;
  padding-bottom: 1px;
  margin: 1px;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
}

.remove-shop:hover {
  background-color: #ff9966;
}

.set-main-shop {
  color: white;
  background-color: cornflowerblue;
  padding-top: 1px;
  padding-bottom: 1px;
  margin: 1px;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
}

.set-main-shop:hover {
  background-color: deepskyblue;
}

.is-main-shop {
  color: white;
  background-color: limegreen;
  padding-top: 1px;
  padding-bottom: 1px;
  margin: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

.shop-list-control {
  border-top: 2px solid #aaaaaa;
  border-left: 2px solid #aaaaaa;
  border-bottom: 2px solid #dddddd;
  border-right: 2px solid #dddddd;
}

.shop-add-button-container {
  margin-left: 15px;
}

.shop-buttons-box {
  margin-right: 5px;
}

.shop-list {
  min-height: 100px;
  max-height: 100px;
  overflow-y: auto;
  padding: 5px;
}